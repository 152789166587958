import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import {
    Container,
    Row,
    Col,
    CarouselItem,
    Carousel,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';

import Isvg from 'react-inlinesvg';

import rightArrow from '../assets/svg/right-arrow.svg';
import slider from '../assets/images/slider.png';
import googlePlay from '../assets/images/google-play-badge.png';
import appStore from '../assets/images/ios.png';


export class HomeSlider extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = {
            activeIndex: 0,
            width: 0
        };
    }



    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth });
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length ? 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {
        const { activeIndex } = this.state;



        const slides = this.props.items.map((item, idx) => {
            let image;
            if (this.state.width < 600) {
                image = item.PhoneImage && item.PhoneImage.scaleImage(400);
            } else if (this.state.width >= 600 && this.state.width < 1025) {
                image = item.TabletImage;
            } else {
                image = item.DesktopImage;
            }
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={idx}
                    slide={true}

                >
                    <img src={item.Image} alt={item.title && item.title} />
                    <div className="content">

                        <Container>
                            <Row>
                                <Col lg={{ size: 4, offset: 0 }} xs="12" className="content-col">
                                    <h3>{item.Title && item.Title}</h3>
                                    <p>{item.Subtitle && item.Subtitle}</p>
                                    {idx == 0 || idx == 1 ? <Link to={'/contact'} className="button">{this.props.translate('Kontaktirajte nas')} </Link> : null}
                                    {idx == 1 ? <Link to={'/category'} className="button">{this.props.translate('Proizvodi')} </Link> : null}
                                    {
                                        idx == 2 ?
                                            <div className="app-buttons">
                                            
                                            <a href="#" className="app-button"><img src={googlePlay} /> </a>
                                            <a href="#" className="app-button"><img src={appStore}/> </a>
                                            </div>
                                            :
                                            null
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </CarouselItem>
            );
        });


        return (
            <div className="home-slider-wrap">
                <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                    touch={true}
                    className="home-slider "
                    interval={4000}
                >
                    <CarouselIndicators items={this.props.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                    {slides}
                </Carousel>

            </div>
        )
    }
}

export default HomeSlider;