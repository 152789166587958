import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';


import account_icon from '../assets/svg/user-icon.svg'
import list_icon from '../assets/svg/list-icon.svg'
import location_icon from '../assets/svg/location-icon.svg'
import heart_icon from '../assets/svg/heart.svg'
import key_icon from '../assets/svg/key-icon.svg'
import mail_icon from '../assets/svg/mail-icon.svg'
import logout_icon from '../assets/svg/logout-icon.svg'

import cart_icon from '../assets/svg/cart-icon.svg';
import map_icon from '../assets/svg/map-icon.svg';
import map_icon_active from '../assets/svg/map-icon-active.svg';
import cart_done_icon_active from '../assets/svg/cart-done-icon-active.svg';

import cart_done_icon from '../assets/svg/cart-done-icon.svg';
import cc_icon from '../assets/svg/credit-card-icon.svg';
import delete_icon from '../assets/svg/delete-icon.svg';
import paypal_icon from '../assets/images/paypal.png';

import image from '../assets/images/no-image.jpg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

import DeliveryAddressForm from '../components/forms/addressForm';

class CartPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.removeCartItem = this.removeCartItem.bind(this);
        this.updateQuantity = this.updateQuantity.bind(this);
        this.updateDeliveryAddress = this.updateDeliveryAddress.bind(this);
        this.updateDeliverTo = this.updateDeliverTo.bind(this);
        this.updateCurrentOrder = this.updateCurrentOrder.bind(this);

        this.state = {
            step: 0,
            deliveryMethod: 1,
            note: '',
            deliverTo: 'BillingAddress',
            _loading: true,
            imageErrors: {},
            deliveryMethods:
                [

                ]

        };

    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        if (this.props.uData && this.props.uData.deliverTo && !this.state.deliverTo) {
            this.setState({
                deliverTo: this.props.uData.deliverTo
            })
        }

        if (this.props.uData && !this.state.initialValues) {
            this.setState({
                initialValues: {
                    FirstName: this.props.uData.ShippingAddress.FirstName,
                    LastName: this.props.uData.ShippingAddress.LastName,
                    Street: this.props.uData.ShippingAddress.Street,
                    Zipcode: this.props.uData.ShippingAddress.Zipcode,
                    City: this.props.uData.ShippingAddress.City,
                    Country: this.props.uData.ShippingAddress.Country,
                    EMail: this.props.uData.ShippingAddress.EMail,
                    Company: this.props.uData.ShippingAddress.Company,
                    Phone: this.props.uData.ShippingAddress.Phone,
                }
            })
        }
    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });



        this.props.socketIOClient.on('updateCurrentOrder', (data) => {
            this.props.socketIOClient.emit("fetchCurrentOrder", {});
            this.setState({
                cartChanged: null,
                _loading: null,
                imageErrors: {}
            })

            if (this.state.step == 1) {

            }

            if (this.state._finishOrder){
                this.props.socketIOClient.emit('executeOrder', {});
            }

        })

        this.props.socketIOClient.on('updateUserData', (data) => {
            //console.log(data);
            if (data.successful) {
                this.props.setUserData(data.user);
                this.setState({
                    showForm: null,
                    initialValues: null,
                    _loading: null
                })

            }
        });



        this.props.socketIOClient.on('fetchCurrentOrder', (data) => {
            //console.log(data);
            this.setState({
                order: data.order,
                deliveryMethods: data.deliveryMethods
            });

            if (data.order.BillingMethod === 'Per-Nachname' || data.order.BillingMethod === 'Bargeld') {
                this.setState({
                    _loading: null
                })
            }
            /*this.setState({
                cartItems: data
            })*/
        });

        this.props.socketIOClient.on('executeOrder', (data) => {
            if (data.successful) {
                this.props.showInfoMessage('Narudžba je primljena.');

                this.setState({
                    step: 3
                })


            }
        })


        this.props.socketIOClient.on('removeCartItem', (data) => {
            this.props.socketIOClient.emit("cartInfo", {});
            this.props.socketIOClient.emit("fetchCurrentOrder", {});

        });


        this.props.socketIOClient.emit("fetchCurrentOrder", {});

        //this.props.socketIOClient.emit("fetchDeliveryMethods", {});



    }

    updateDeliveryAddress(data) {
        this.setState({
            _loading: true
        })
        //console.log(data);
        let obj = {
            'ShippingAddress.FirstName': data.FirstName,
            'ShippingAddress.LastName': data.LastName,
            'ShippingAddress.Street': data.Street,
            'ShippingAddress.Zipcode': data.Zipcode,
            'ShippingAddress.City': data.City,
            'ShippingAddress.Country': data.Country,
            'ShippingAddress.EMail': data.EMail,
            'ShippingAddress.Company': data.Company,
            'ShippingAddress.Phone': data.Phone,

        }

        this.props.socketIOClient.emit("updateUserData", obj);
    }

    updateDeliverTo() {
        let obj = {
            'deliverTo': this.state.deliverTo
        };

        this.setState({
            _loading: true
        })

        this.props.socketIOClient.emit("updateUserData", obj);

    }

    updateCurrentOrder() {
        this.setState({
            _loading: true
        });

        let obj = {
            products: this.state.order.products
        }

        if (this.state.Coupon) {
            obj.Coupon = this.state.Coupon;
        }

        this.props.socketIOClient.emit("updateCurrentOrder", obj);

    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        //this.props.socketIOClient.removeAllListeners("fetchProduct");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    login(data) {
        //console.log(data);
        this.props.socketIOClient.emit("userLogin", data);
    }


    removeCartItem(item) {
        //console.log(true);
        this.props.socketIOClient.emit("removeCartItem", { productId: item._id });
    }

    updateQuantity(idx, val) {
        let order = this.state.order;

        order.products[idx].cartQuantity = val;

        this.setState({
            order: order,
            cartChanged: true
        })

    }



    render() {
        let cartItems = this.state.order ? this.state.order.products : [];
        //console.log(this.state.order);
        return (
            <div className="account-wrap cart-wrap">

                {
                    /* !this.props.uData ? <Redirect to='/login' ></Redirect> : null}*/
                }

                <Container>
                    <Row>
                    <Col lg="3" className="d-none d-lg-block">
                    <div className="categories">

                                <h3>{this.props.translate('KATEGORIJE')}</h3>
                                <Categories {...this.props} selectCategory={(cat, level) => {
                                    let state = { page: 0 };
                                    state['_selectedCategory' + level] = cat._id;

                                    this.setState(state);

                                }}

                                    _selectedCategory0={this.state._selectedCategory0}
                                    _selectedCategory1={this.state._selectedCategory1}
                                    _selectedCategory2={this.state._selectedCategory2}
                                    _selectedCategory3={this.state._selectedCategory3}
                                    _selectedCategory4={this.state._selectedCategory4}


                                />




                            </div>
                        </Col>

                        <Col lg="9" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <ul className="cart-progress">

                                            <li className={this.state.step === 0 ? 'active' : 'done'}>
                                                <div className="icon">
                                                    <Isvg src={cart_icon} />
                                                </div>
                                                <p>{this.props.translate('1. Korpa')}</p>
                                               {/* <p>{this.props.translate('Količina, dostava i plaćanje')}</p>*/}

                                            </li>

                                            <li className={this.state.step === 1 ? 'active' : this.state.step > 1 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={this.state.step >= 1 ? map_icon_active : map_icon} />
                                                </div>
                                                <p>{this.props.translate('2. Adresa')}</p>
                                                {/* <p>{this.props.translate('Adresa za isporuku')}</p>*/}

                                            </li>

                                           {/* <li className={this.state.step === 2 ? 'active' : this.state.step > 2 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={this.state.step >= 2 ? cart_done_icon_active : cart_done_icon} />
                                                </div>
                                                <p>{this.props.translate('3. Narudžba')}</p>
                                                <p>{this.props.translate('Proverite narudžbu')}</p>

                                            </li>*/
                            }

                                            <li className={this.state.step === 3 ? 'active' : this.state.step > 3 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={cc_icon} />
                                                </div>
                                                <p>{this.props.translate('3. Potvrda')}</p>
                                               {/*  <p>{this.props.translate('Potvrda o prijemu narudžbe')}</p>*/}


                                            </li>

                                        </ul>
                                    </Col>


                                    <Col lg="12">
                                        {this.state.step === 0 ?
                                            <Container className="box-container">

                                                <Row>

                                                    <Col lg="12">

                                                        <p>
                                                            Proverite svoje unose. Možete ih ispraviti u svakom koraku narudžbe. Ako je potrebno, kliknite na gore navedene korake narudžbe. Narudžba će biti prenesena u poslednjem koraku. Imajte na umu podatke o pravu na odustajanje, politiku privatnosti i naše uslove i odredbe.
                                                        </p>
                                                    </Col>


                                                    <Col lg="3">
                                                        <Link to='' className='back'>{this.props.translate('Nastavite kupovati')}</Link>
                                                    </Col>
                                                    <Col lg="9" className="checkout-buttons">

                                                        {this.state.cartChanged ?

                                                            <button className="button" onClick={() => this.updateCurrentOrder()}>{this.props.translate('Osveži korpu')}</button>

                                                            :
                                                            cartItems.length ?
                                                            < button className="button " onClick={() => this.setState({ _loading: true, step: 1, showForm: this.props.uData ? null : true }, () => { this.props.socketIOClient.emit("updateCurrentOrder", { Note: this.state.note, Coupon: this.state.coupon }) })}>{this.props.translate('NASTAVI')}</button>
                                                    : null



                                                }
                                                    </Col>
                                                <Col lg="12">
                                                    <Container className="cart-table">
                                                        <Row className="header">
                                                            <Col lg="2" xs="2">{this.props.translate('Količina')}</Col>
                                                            <Col lg="5" xs="7">{this.props.translate('Naziv artikla')}</Col>

                                                            <Col lg="2" xs="2" className="number d-none d-md-block">{this.props.translate('Cijena')}</Col>

                                                            <Col lg="2" xs="2" className="number">{this.props.translate('Ukupno')}</Col>
                                                            <Col lg="1" xs="1"></Col>

                                                        </Row>

                                                        {
                                                            cartItems.map((item, idx) => {
                                                                return (
                                                                    <Row className="article-info" key={idx}>
                                                                        <Col lg="2" xs="2">
                                                                            <input type="text" value={item.cartQuantity} onChange={(e) => this.updateQuantity(idx, e.target.value)} /> <label>{this.props.translate('kom.')}</label>
                                                                        </Col>
                                                                        <Col lg="5" xs="7">
                                                                            <img onError={() => {
                                                                                let imageErrors = this.state.imageErrors;
                                                                                imageErrors[item.Image] = true;
                                                                                this.setState({ imageErrors })
                                                                            }} src={!this.state.imageErrors[item.Images && item.Images.length && item.Images[0]] ? (item.Images && item.Images.length && item.Images[0]) ? item.Images[0] : image : image} />
                                                                            <span>{item.Name && item.Name}</span>
                                                                        </Col>
                                                                        <Col lg="2" xs="2" className="number d-none d-md-block">
                                                                            {item.price ? item.price.formatPrice(this.props.currency) : null}
                                                            </Col>
                                                                        <Col lg="2" xs="2" className="number">
                                                                            {item.price ? (parseFloat(item.price) * item.cartQuantity).formatPrice(this.props.currency) : null}
                                                            </Col>
                                                                        <Col lg="1" xs="1" className="delete" onClick={() => this.removeCartItem(item)}>
                                                                            <Isvg src={delete_icon} />
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            })

                                                        }
                                                         

                                                        <Row className="article-info">
                                                            <Col lg="7" xs="9">
                                                                {this.props.translate('Suma stavki')}
                                                            </Col>
                                                            <Col lg="2" xs="2" className="number d-none d-md-block">

                                                            </Col>
                                                            <Col lg="2" xs="2" className="number">
                                                                {this.state.order && this.state.order.itemsTotal.formatPrice(this.props.currency)}
                                                            </Col>
                                                            <Col lg="1" xs="1" className="delete">

                                                            </Col>
                                                        </Row>


                                                        <Row className="article-info">
                                                            <Col lg="2" xs="3" className="d-none d-md-block">
                                                                {this.props.translate('Način isporuke')}
                                                            </Col>
                                                            <Col lg="5" xs="9" className="number">
                                                                {this.state.order ?
                                                                    <Dropdown className="select-field" isOpen={this.state.dropdownOpen1} toggle={() => { this.setState({ dropdownOpen1: !this.state.dropdownOpen1 }) }}>
                                                                        <DropdownToggle nav caret>
                                                                            {this.state.order.DeliveryMethod.name}
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-animation">
                                                                            {
                                                                                this.state.deliveryMethods.map((item, idx) => {
                                                                                    return (
                                                                                        <DropdownItem onClick={() => { this.props.socketIOClient.emit("updateCurrentOrder", { DeliveryMethod: item }); }}>{item.name}</DropdownItem>

                                                                                    )
                                                                                })
                                                                            }
                                                                        </DropdownMenu>
                                                                    </Dropdown>

                                                                    : null
                                                                }
                                                            </Col>
                                                            <Col lg="2" xs="2" className="number d-none d-md-block">

                                                            </Col>
                                                            <Col lg="2" xs="2" className="number">
                                                                {parseFloat(this.state.order ? this.state.order.DeliveryMethod.price : 0).formatPrice(this.props.currency)} 
                                                            </Col>
                                                            <Col lg="1" xs="1" className="delete">

                                                            </Col>
                                                        </Row>


                                                        {/*<Row className="article-info">
                                                            <Col lg="2" xs="3" className="d-none d-md-block">
                                                                {this.props.translate('Način plaćanja')}
                                                            </Col>
                                                            <Col lg="5" xs="9" >
                                                                <Dropdown className="select-field" isOpen={this.state.dropdownOpen2} toggle={() => { this.setState({ dropdownOpen2: !this.state.dropdownOpen2 }) }}>
                                                                    <DropdownToggle nav caret>
                                                                        {this.state.order && this.state.order.BillingMethod === 'placanje-prilikom-pouzeca' ? 'Plaćanje prilikom pouzeća' : ''}
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-animation">
                                                                        <DropdownItem onClick={() => { this.props.socketIOClient.emit("updateCurrentOrder", { BillingMethod: 'placanje-prilikom-pouzeca' }); }}>Plaćanje prilikom pouzeća</DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>

                                                            </Col>
                                                            <Col lg="2" xs="2" className="number d-none d-md-block">
                                                            </Col>
                                                            <Col lg="2" xs="2" className="number">
                                                                 0,00 {this.props.currency.code}

                                                            </Col>
                                                            <Col lg="1" xs="1" className="delete">
                                                            </Col>
                                                            </Row>*/}

                                                        <Row className="article-info">
                                                            <Col lg="7" xs="9" className="text-bold">
                                                                {this.props.translate('UKUPNO')}
                                                            </Col>
                                                            <Col lg="2" xs="2" className="number d-none d-md-block">

                                                            </Col>
                                                            <Col lg="2" xs="2" className="number text-bold">
                                                                {this.state.order && this.state.order.total.formatPrice(this.props.currency)} 
                                                            </Col>
                                                            <Col lg="1" xs="1" className="delete">

                                                            </Col>
                                                        </Row>





                                                    </Container>



                                                </Col>



                                                <Col lg="7" xs="7" className="notes-input">
                                                    <div className="input-wrap">
                                                        <label>{this.props.translate('Napomena:')}</label>
                                                        <input type="text" value={this.state.note} onChange={(e) => { this.setState({ note: e.target.value }) }} />
                                                    </div>
                                                </Col>

                                                <Col lg="5" xs="5" className="checkout-button">
                                                    {this.state.cartChanged ?

                                                        <button className="button button-right" onClick={() => this.updateCurrentOrder()}>{this.props.translate('Osveži korpu')}</button>

                                                        :
                                                        cartItems.length ?
                                                            <button className="button button-right " onClick={() => this.setState({ _loading: true, step: 1, showForm: this.props.uData ? null : true }, () => { this.props.socketIOClient.emit("updateCurrentOrder", { Note: this.state.note, Coupon: this.state.coupon }) })}>{this.props.translate('NASTAVI')}</button>
                                                            : null



                                                    }
                                                </Col>

                                                </Row>
                                            </Container>

                                        : null}



                                        {this.state.step === 1 ?
                                            <Container className="box-container">

                                                <Row>
                                                    <Col lg="12">
                                                        <p>
                                                            Proverite svoje unose. Možete ih ispraviti u svakom koraku narudžbe. Ako je potrebno, kliknite na gore navedene korake narudžbe. Narudžba će biti prenesena u poslednjem koraku. Imajte na umu podatke o pravu na odustajanje, politiku privatnosti i naše uslove i odredbe.
                                                        </p>
                                                    </Col>

                                                    {this.props.uData && this.state.order && this.state.order.ShippingAddress ?

                                                        <Col lg="12" className="checkout-buttons">

                                                            <button className="button " onClick={() => { this.setState({ step: 2 }); }}>{this.props.translate('NASTAVI')}</button>


                                                        </Col>
                                                        : null
                                                    }
                                                    <Col lg="12">

                                                        {this.props.uData ?
                                                            <Container className="table">
                                                                <Row className="header">
                                                                    <Col lg="6">
                                                                        {this.props.translate('Adresa za obračun')}
                                                                    </Col>
                                                                    <Col lg="6">
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <p>{this.props.uData.BillingAddress.FirstName} {this.props.uData.BillingAddress.LastName}</p>
                                                                        <p>{this.props.uData.BillingAddress.Street}</p>
                                                                        <p>{this.props.uData.BillingAddress.Zipcode} {this.props.uData.BillingAddress.City}</p>
                                                                        <br/>
                                                                        <p>{this.props.uData.BillingAddress.Phone}</p>

                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="input-wrap radio-button-wrap" onClick={() => this.setState({ deliverTo: 'BillingAddress' }, () => { this.updateDeliverTo(); this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: this.props.uData.BillingAddress }) })}>
                                                                            <div className={this.state.deliverTo === 'BillingAddress' ? 'radio-button radio-button-checked' : 'radio-button'}></div> {this.props.translate('Dostavi na ovu adresu')}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                            : null
                                                        }

                                                        {this.props.uData  && this.props.uData.ShippingAddress.FirstName && this.props.uData.ShippingAddress.LastName && this.props.uData.ShippingAddress.Street && this.props.uData.ShippingAddress.Zipcode && this.props.uData.ShippingAddress.City && this.props.uData.ShippingAddress.Country ?
                                                            <Container className="table">
                                                                <Row className="header">
                                                                    <Col lg="6">
                                                                        {this.props.translate('Adresa za isporuku')}
                                                                    </Col>
                                                                    <Col lg="6">
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <p>{this.props.uData.ShippingAddress.FirstName} {}</p>
                                                                        <p>{this.props.uData.ShippingAddress.Street}</p>
                                                                        <p>{this.props.uData.ShippingAddress.Zipcode} {this.props.uData.ShippingAddress.City}</p>
                                                                        <br />
                                                                        <p>{this.props.uData.ShippingAddress.Phone}</p>

                                                                    </Col>
                                                                    <Col lg="6" onClick={() => this.setState({ deliverTo: 'ShippingAddress' }, () => { this.updateDeliverTo(); this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: this.props.uData.ShippingAddress }) })}>
                                                                        <div className="input-wrap radio-button-wrap">
                                                                            <div className={this.state.deliverTo === 'ShippingAddress' ? 'radio-button radio-button-checked' : 'radio-button'}></div> {this.props.translate('Dostavi na ovu adresu')}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Container>

                                                            : null
                                                        }

                                                        {!this.props.uData && this.state.order && this.state.order.ShippingAddress ?
                                                            <Container className="table">
                                                                <Row className="header">
                                                                    <Col lg="6">
                                                                        {this.props.translate('Adresa za isporuku')}
                                                                    </Col>
                                                                    <Col lg="6">
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <p>{this.state.order.ShippingAddress.FirstName} {this.state.order.ShippingAddress.LastName}</p>
                                                                        <p>{this.state.order.ShippingAddress.Street}</p>
                                                                        <p>{this.state.order.ShippingAddress.Zipcode} {this.state.order.ShippingAddress.City}</p>
                                                                        <br />
                                                                        <p>{this.state.order.ShippingAddress.Phone}</p>

                                                                    </Col>
                                                                    <Col lg="6" onClick={() => this.setState({ deliverTo: 'ShippingAddress' }, () => { this.updateDeliverTo(); this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: this.props.uData.ShippingAddress }) })}>
                                                                    </Col>
                                                                </Row>
                                                            </Container>

                                                            : null
                                                        }
                                                        <Col lg="12" className="button-group">
                                                           { /*!this.state.showForm ? <button className="button button-black" onClick={() => { this.setState({ showForm: true }) }}>{this.props.translate('Izmijeni adresu za dostavu')}</button> : null*/}
                                                            {(this.state.order && this.state.order.ShippingAddress) || this.props.uData ?
                                                                <button className="button button-right" onClick={() => { 
                                                                    if (this.props.uData) {
                                                                        //this.updateDeliveryAddress(data);
                                                                        this.setState({ _loading: true, showForm: null, _finishOrder: true }, () => {
                                                                            this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: this.props.uData.deliverTo == 'ShippingAddress' ? this.props.uData.ShippingAddress : this.props.uData.BillingAddress });

                                                                        });
                                                                    } 


                                                                 }} >{this.props.translate('NASTAVI')}</button>
                                                                :
                                                                null
                                                            }
                                                        </Col>

                                                        {
                                                            this.state.showForm ?


                                                                <Col lg="12" className="form-container">
                                                                        <h3>{this.props.translate('Adresa za dostavu')}</h3>

                                                                

                                                                    <DeliveryAddressForm initialValues={this.state.initialValues} translate={this.props.translate} onSubmit={(data) => {
                                                                        if (this.props.uData) {
                                                                            this.updateDeliveryAddress(data);
                                                                            this.setState({ _loading: true, showForm: null, _finishOrder: true }, () => {
                                                                                this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: data });

                                                                            });
                                                                        } else {
                                                                            this.setState({ _loading: true, showForm: null, _finishOrder: true }, () => {
                                                                                this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: data })
                                                                            });
                                                                        }
//

                                                                    }}></DeliveryAddressForm>




                                                                </Col>

                                                                : null
                                                        }

                                                    </Col>



                                                </Row>
                                            </Container>

                                            :
                                            null
                                        }


                                        {/*this.state.step === 2 ?
                                            <Container className="box-container">
                                                <Row>
                                                    <Col lg="12">
                                                        <p>
                                                            Proverite svoje unose. Možete ih ispraviti u svakom koraku narudžbe. Ako je potrebno, kliknite na gore navedene korake narudžbe. Narudžba će biti prenesena u poslednjem koraku. Imajte na umu podatke o pravu na odustajanje, politiku privatnosti i naše uslove i odredbe.
                                                                                                        </p>
                                                    </Col>

                                                    <Col lg="12">
                                                        <div className="input-wrap" onClick={() => { this.setState({ acceptTerms: !this.state.acceptTerms }) }}>
                                                            <div className={this.state.acceptTerms ? 'checkbox checked' : 'checkbox'}></div><span className={this.state.acceptTerms ? '' : 'required'}>{this.props.translate('Pročitao sam i prihvatam ')} <Link to='/page/terms-and-conditions' className="text-primary text-bold">{this.props.translate('Uslove i odredbe')}</Link> </span>
                                                        </div>
                                                    
                                                    </Col>

                                                    <Col lg="12">

                                                        {this.props.uData ?
                                                            <Container className="table">
                                                                <Row className="header">
                                                                    <Col lg="6">
                                                                        {this.props.translate('Adresa za obračun')}
                                                                    </Col>
                                                                    <Col lg="6">
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <p>{this.props.uData.BillingAddress.FirstName} {this.props.uData.BillingAddress.LastName}</p>
                                                                        <p>{this.props.uData.BillingAddress.Street}</p>
                                                                        <p>{this.props.uData.BillingAddress.Zipcode} {this.props.uData.BillingAddress.City}</p>
                                                            <br/>
                                                            <p>{this.props.uData.BillingAddress.Phone}</p>

                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <p>{this.props.uData.ShippingAddress.FirstName} {this.props.uData.ShippingAddress.LastName}</p>
                                                                        <p>{this.props.uData.ShippingAddress.Street}</p>
                                                                        <p>{this.props.uData.ShippingAddress.Zipcode} {this.props.uData.ShippingAddress.City}</p>
                                                                        <br/>
                                                                        <p>{this.props.uData.ShippingAddress.Phone}</p>

                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                            :

                                                            <Container className="table">
                                                                <Row className="header">
                                                                    <Col lg="6">
                                                                        {this.props.translate('Adresa za isporuku')}
                                                                    </Col>
                                                                    <Col lg="6">
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <p>{this.state.order.ShippingAddress.FirstName} {this.state.order.ShippingAddress.LastName}</p>
                                                                        <p>{this.state.order.ShippingAddress.Street}</p>
                                                                        <p>{this.state.order.ShippingAddress.Zipcode} {this.state.order.ShippingAddress.City}</p>

                                                                    </Col>
                                                                    <Col lg="6">

                                                                    </Col>
                                                                </Row>
                                                            </Container>

                                                        }

                                                        <Container className="cart-table">
                                                            <Row className="header">
                                                                <Col lg="2" xs="2">{this.props.translate('Količina')}</Col>
                                                                <Col lg="6" xs="6">{this.props.translate('Naziv artikla')}</Col>

                                                                <Col lg="2" xs="2" className="number">{this.props.translate('Cijena')}</Col>

                                                                <Col lg="2" xs="2" className="number">{this.props.translate('Ukupno')}</Col>

                                                            </Row>


                                                            {this.state.order ?
                                                                this.state.order.products.map((item, idx) => {
                                                                    return (
                                                                        <Row className="article-info" key={idx}>
                                                                            <Col lg="2" xs="2">
                                                                                {item.cartQuantity}
                                                                            </Col>
                                                                            <Col lg="6" xs="6">
                                                                                <img onError={() => {
                                                                                    let imageErrors = this.state.imageErrors;
                                                                                    imageErrors[item.Image] = true;
                                                                                    this.setState({ imageErrors })
                                                                                }} src={!this.state.imageErrors[item.Images && item.Images.length && item.Images[0]] ? (item.Images && item.Images.length && item.Images[0]) ? item.Images[0] : image : image} />
                                                                            <span>{item.Name} - {item.package.name}</span>
                                                                            </Col>
                                                                            <Col lg="2" xs="2" className="number">
                                                                                {item.price ? parseFloat(item.price).formatPrice(this.props.currency) : null} 
                                                            </Col>
                                                                            <Col lg="2" xs="2" className="number">
                                                                                {item.price ? (parseFloat(item.price) * item.cartQuantity).formatPrice(this.props.currency) : null} 
                                                            </Col>

                                                                        </Row>
                                                                    )
                                                                })
                                                                : null
                                                            }

                                                            <Row className="article-info">
                                                                <Col lg="8" xs="8">
                                                                    {this.props.translate('Suma stavki')}
                                                                </Col>
                                                                <Col lg="2" xs="2" className="number">

                                                                </Col>
                                                                <Col lg="2" xs="2" className="number">
                                                                    {this.state.order && this.state.order.itemsTotal.formatPrice(this.props.currency)}
                                                            </Col>

                                                            </Row>


                                                            <Row className="article-info">
                                                                <Col lg="2" xs="3" className="d-none d-md-block">
                                                                    {this.props.translate('Način isporuke')}
                                                                </Col>
                                                                <Col lg="6" xs="10">
                                                                    {this.state.order.DeliveryMethod.name}
                                                                </Col>
                                                                <Col lg="2" xs="2" className="number d-none d-md-block">

                                                                </Col>
                                                                <Col lg="2" xs="2" className="number">
                                                                    {parseFloat(this.state.order.DeliveryMethod.price).formatPrice(this.props.currency)}
                                                            </Col>

                                                            </Row>


                                                            <Row className="article-info">
                                                                <Col lg="2" xs="3" className="d-none d-md-block">
                                                                    {this.props.translate('Način plaćanja')}
                                                                </Col>
                                                                <Col lg="6" xs="10" >
                                                                    {this.state.order && this.state.order.BillingMethod === 'placanje-prilikom-pouzeca' ? 'Plaćanje prilikom pouzeća' : ''}

                                                                </Col>
                                                                <Col lg="2" xs="2" className="number d-none d-md-block">
                                                                </Col>
                                                                <Col lg="2" xs="2" className="number">
                                                                    0,00 {this.props.currency.code}
                                                                </Col>

                                                            </Row>

                                                         
                                                            <Row className="article-info">
                                                                <Col lg="8" xs="8" className="text-bold">
                                                                    {this.props.translate('UKUPNO')}
                                                                </Col>
                                                                <Col lg="2" xs="2" className="number">

                                                                </Col>
                                                                <Col lg="2" xs="2" className="number text-bold">
                                                                    {this.state.order && this.state.order.total.formatPrice(this.props.currency)}
                                                            </Col>
                                                            </Row>





                                                        </Container>

                                                        <Col lg="12">
                                                            <button className="button button-right" onClick={() => { if (!this.state.acceptTerms) { return; } this.props.socketIOClient.emit('executeOrder', {}); }}>ZAVRŠI NARUDŽBU</button>

                                                        </Col>



                                                    </Col>



                                                </Row>
                                            </Container>

                                            :
                                            null
                                                        */}
                                        {this.state.step === 3 ?
                                            <Container className="box-container">
                                                <Row>
                                                    <Col lg="12">
                                                        <h3>{this.props.translate('Narudžba je uspešno primljena.')}</h3>
                                                        <p>
                                                            {this.props.translate('Očekujte dostavu u roku od 24h do 48h.')}

                                                        </p>
                                                    </Col>

                                                </Row>
                                            </Container>

                                            : null
                                        }

                                        

                                    </Col>

                                </Row>
                            </Container>

                        </Col>

                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(CartPage));
